<script>
import { mapState } from 'vuex'

export default {
   data() {
      return {}
   },
   computed: {
      ...mapState({
         user: state => state.Profile.user,
         loginInfo: state => state.Profile.loginInfo,
         logs: state => state.Profile.log,
      }),
      relativeTime() {
         return date => (this.$moment(date) ? this.$moment(date).fromNow() : date)
      },
      logVariant() {
         const data = {
            create: 'success',
            update: 'info',
            delete: 'danger',
         }
         return status => (data[status] ? data[status] : status)
      },
      logIcon() {
         const data = {
            create: 'PlusIcon',
            update: 'EditIcon',
            delete: 'TrashIcon',
         }
         return status => (data[status] ? data[status] : status)
      },
   },

}
</script>

<template>
   <div>
      <b-card>
         <b-row>
            <!-- User Info: Left col -->
            <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column mb-2">
               <!-- User Avatar & Action Buttons -->
               <div class="d-flex justify-content-start">
                  <b-avatar
                     :src="require('@/assets/images/avatars/13-small.png')"
                     variant="primary"
                     size="80px"
                     rounded
                  />
                  <div class="d-flex flex-column ml-1">
                     <div class="mb-1">
                        <h4 class="mb-0">
                           {{ user.name }}
                        </h4>
                        <span class="card-text">{{ user.username }}</span>
                     </div>
                  </div>
               </div>

            </b-col>
         </b-row>
         <b-row>
            <b-col lg="4" md="6">
               <div class="d-flex align-items-center mb-2">
                  <b-avatar variant="primary" rounded>
                     <feather-icon icon="UnlockIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                     <h5 class="mb-0">
                        Role
                     </h5>
                     <small>{{ user.role_name }}</small>
                  </div>
               </div>
            </b-col>
            <b-col lg="4" md="6">
               <div class="d-flex align-items-center mb-2">
                  <b-avatar variant="primary" rounded>
                     <feather-icon icon="ChromeIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                     <h5 class="mb-0">
                        IP Address
                     </h5>
                     <small>{{ loginInfo.ip_address | moment("DD-MM-YYYY | HH:mm:ss") }}</small>
                  </div>
               </div>
            </b-col>
            <b-col lg="4" md="6">
               <div class="d-flex align-items-center mb-2">
                  <b-avatar variant="primary" rounded>
                     <feather-icon icon="LogInIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                     <h5 class="mb-0">
                        Login At
                     </h5>
                     <small>{{ loginInfo.created_at | moment("DD-MM-YYYY | HH:mm:ss") }}</small>
                  </div>
               </div>
            </b-col>
            <b-col lg="4" md="6">
               <div class="d-flex align-items-center mb-2">
                  <b-avatar variant="primary" rounded>
                     <feather-icon icon="LogInIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                     <h5 class="mb-0">
                        Login At <small>(Relative Time)</small>
                     </h5>
                     <small>{{ relativeTime(loginInfo.created_at) }}</small>
                  </div>
               </div>
            </b-col>
            <b-col lg="4" md="6">
               <div class="d-flex align-items-center mb-2">
                  <b-avatar variant="primary" rounded>
                     <feather-icon icon="ClockIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                     <h5 class="mb-0">
                        Last Activity
                     </h5>
                     <small>{{ loginInfo.updated_at | moment("DD-MM-YYYY | HH:mm:ss") }}</small>
                  </div>
               </div>
            </b-col>
            <b-col lg="4" md="6">
               <div class="d-flex align-items-center mb-2">
                  <b-avatar variant="primary" rounded>
                     <feather-icon icon="ClockIcon" size="18" />
                  </b-avatar>
                  <div class="ml-1">
                     <h5 class="mb-0">
                        Last Activity <small>(Relative Time)</small>
                     </h5>
                     <small>{{ relativeTime(loginInfo.updated_at) }}</small>
                  </div>
               </div>
            </b-col>
         </b-row>
      </b-card>

      <b-row>
         <b-col md="6">
            <b-card class="card-transaction" no-body>
               <b-card-header>
                  <b-card-title>Log Activity <br><small>Top 10</small></b-card-title>
               </b-card-header>

               <b-card-body>
                  <div v-if="!logs">
                     <h5>Nothing</h5>
                  </div>
                  <div v-for="log in logs" :key="log.id" class="transaction-item">
                     <b-media no-body>
                        <b-media-aside>
                           <b-avatar rounded size="30" :variant="logVariant(log.action)">
                              <feather-icon size="16" :icon="logIcon(log.action)" />
                           </b-avatar>
                        </b-media-aside>
                        <b-media-body>
                           <h6 class="transaction-title">
                              {{ log.resource_uid }}
                           </h6>
                           <small>{{ log.action }}</small>
                        </b-media-body>
                     </b-media>
                     <div class="font-weight-bolder">
                        <small>{{ log.created_at | moment("HH:mm:ss | DD-MM-YYYY") }}</small>
                     </div>
                  </div>
               </b-card-body>
            </b-card>
         </b-col>
      </b-row>

   </div>
</template>

<style scoped>
.card-transaction .transaction-item {
   border-bottom: 1px solid antiquewhite;
}
</style>
