<script>
import { mapActions } from 'vuex'
import Ripple from 'vue-ripple-directive'

export default {
   directives: {
      Ripple,
   },
   data() {
      return {
         passwordValueOld: '',
         newPasswordValue: '',
         RetypePassword: '',
         passwordFieldTypeOld: 'password',
         passwordFieldTypeNew: 'password',
         passwordFieldTypeRetype: 'password',
      }
   },
   computed: {
      isLoading() {
         return this.$store.state.isLoading
      },
      passwordToggleIconOld() {
         return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      passwordToggleIconNew() {
         return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
      passwordToggleIconRetype() {
         return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
      },
   },
   watch: {
      isLoading(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   methods: {
      ...mapActions({
         changePassword: 'Profile/changePassword',
      }),
      onChangePassword() {
         if (!this.passwordValueOld || !this.newPasswordValue || !this.RetypePassword) {
            this.$toast.error('Form Belum lengkap')
            return
         }

         const data = {
            password: this.passwordValueOld,
            password_new: this.newPasswordValue,
            password_new_retype: this.RetypePassword,
         }

         this.changePassword(data).then(res => {
            this.passwordValueOld = ''
            this.newPasswordValue = ''
            this.RetypePassword = ''
            this.$toast.success(res.message)
         })
      },
      togglePasswordOld() {
         this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
      },
      togglePasswordNew() {
         this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
      },
      togglePasswordRetype() {
         this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
      },
   },
}
</script>

<template>
   <b-card>
      <!-- form -->
      <b-form>
         <b-row>
            <!-- old password -->
            <b-col md="6">
               <b-form-group label="Old Password" label-for="account-old-password">
                  <b-input-group class="input-group-merge">
                     <b-form-input
                        id="account-old-password"
                        v-model="passwordValueOld"
                        name="old-password"
                        :type="passwordFieldTypeOld"
                        placeholder="Old Password"
                        required
                     />
                     <b-input-group-append is-text>
                        <feather-icon :icon="passwordToggleIconOld" class="cursor-pointer" @click="togglePasswordOld" />
                     </b-input-group-append>
                  </b-input-group>
               </b-form-group>
            </b-col>
            <!--/ old password -->
         </b-row>
         <b-row>
            <!-- new password -->
            <b-col md="6">
               <b-form-group label-for="account-new-password" label="New Password">
                  <b-input-group class="input-group-merge">
                     <b-form-input
                        id="account-new-password"
                        v-model="newPasswordValue"
                        :type="passwordFieldTypeNew"
                        name="new-password"
                        placeholder="New Password"
                        required
                     />
                     <b-input-group-append is-text>
                        <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
                     </b-input-group-append>
                  </b-input-group>
               </b-form-group>
            </b-col>
            <!--/ new password -->

            <!-- retype password -->
            <b-col md="6">
               <b-form-group label-for="account-retype-new-password" label="Retype New Password">
                  <b-input-group class="input-group-merge">
                     <b-form-input
                        id="account-retype-new-password"
                        v-model="RetypePassword"
                        :type="passwordFieldTypeRetype"
                        name="retype-password"
                        placeholder="New Password"
                        required
                     />
                     <b-input-group-append is-text>
                        <feather-icon
                           :icon="passwordToggleIconRetype"
                           class="cursor-pointer"
                           @click="togglePasswordRetype"
                        />
                     </b-input-group-append>
                  </b-input-group>
               </b-form-group>
            </b-col>
            <!--/ retype password -->

            <!-- buttons -->
            <b-col cols="12">
               <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" @click="onChangePassword">
                  Save changes
               </b-button>
               <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="reset"
                  variant="outline-secondary"
                  class="mt-1"
               >
                  Reset
               </b-button>
            </b-col>
            <!--/ buttons -->
         </b-row>
      </b-form>
   </b-card>
</template>
